// user reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED ='SET_UNAUTHENTICATED';
export const SET_USER ='SET_USER';
export const LOADING_USER= 'LOADING_USER';

//UI reducer types
export const SET_ERRORS ='SET_ERRORS';
export const LOADING_UI ='LOADING_UI';
export const CLEAR_ERRORS ='CLEAR_ERRORS';
export const SIGNUP_ERRORS = 'SIGNUP_ERRORS';
export const RESET_ERROR= 'RESER_ERROR';
export const RESET_P = 'RESET_P';
export const LOADING_EUI= 'LOADING_EUI';
export const LOADING_DATA = 'LOADING_DATA';
export const POST_ERRORS= 'POST_ERRORS';
export const STOP_UI_LOADING= 'STOP_UI_LOADING';

//data reducer types
export const SET_WESHOUT_ERROR = 'SET_WESHOUT_ERROR';

export const LIKE_WESHOUT = 'LIKE_WESHOUT';
export const UNLIKE_WESHOUT = 'UNLIKE_WESHOUT';
export const SET_WESHOUTS = 'SET_WESHOUTS';
export const DELETE_WESHOUT = 'DELETE_WESHOUT';
export const POST_WESHOUT ='POST_WESHOUT';
export const CLEAR_POSTERRORS='CLEAR_POSTERRORS';
export const CLEAR_CLOSE_ON_RECEIVE= 'CLEAR_CLOSE_ON_RECEIVE';
export const A_SINGLE_POST= 'A_SINGLE_POST';
export const CREATE_COMMENT = 'CREATE_COMMENT';
export const COMMENT_ERRORS = 'COMMENT_ERRORS';
export const DETAILS_OF_A_USER='DETAILS_OF_A_USER';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';
export const OPEN_POST_FROM_MENU ='OPEN_POST_FROM_MENU';
export const CLOSE_POST_FROM_MENU ='CLOSE_POST_FROM_MENU';
export const OPEN_NOTIFICATIONS_FROM_MENU= 'OPEN_NOTIFICATIONS_FROM_MENU';
export const CLOSE_NOTIFICATIONS_FROM_MENU= 'CLOSE_NOTIFICATIONS_FROM_MENU';


//on All reducers
export const NETWORK_ERROR_OR_PROBLEM= 'NETWORK_ERROR_OR_PROBLEM';
export const CLEAR_NETWORK_ERROR = 'CLEAR_NETWORK_ERROR';

